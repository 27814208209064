// These set up types for segment analytics
/// <reference types="@types/segment-analytics" />
import { TrackingProperties } from '@flock/shared-ui'
import { localStore, UserEventType } from '@flock/utils'
import { v4 as uuidv4 } from 'uuid'
// @ts-ignore
import TimeMe from 'timeme.js'
import { LandingTrackUserActionDocument } from '@flock/flock-gql-server/src/__generated__/graphql'
import { client } from './apollo'

const USER_ID_KEY = 'FLOCK-USER-ID'

declare global {
  interface Window {
    analytics: SegmentAnalytics.AnalyticsJS
  }
}

const EVENT_TOKEN_KEY = 'FLOCK-EVENT-TOKEN'

// If logSegment is true, events are not sent to segment and are
// instead logged to the console.
const logSegment = process.env.GATSBY_LOG_SEGMENT === 'true'
const debugSegment = () =>
  logSegment || window.location.href.includes('localhost')

export const shouldTrack = () => {
  let doNotTrack = false
  if (typeof window !== 'undefined') {
    doNotTrack =
      window.doNotTrack === '1' ||
      navigator.doNotTrack === 'yes' ||
      navigator.doNotTrack === '1' ||
      localStore?.getItem('disableTracking') === 'true' ||
      debugSegment()
  }
  return !doNotTrack
}

export const getEventTokenUUID = (): string => {
  let token = localStore?.getItem(EVENT_TOKEN_KEY)
  if (!token) {
    token = uuidv4()
    localStore?.setItem(EVENT_TOKEN_KEY, token!)
  }
  return token!
}

export const getQueryParamsAsJson = () => {
  if (typeof window !== 'undefined') {
    const { search } = window.location
    const params = new URLSearchParams(search)
    const queryParams: { [k: string]: string } = {}
    params.forEach((value: string, key: string) => {
      queryParams[key] = value
    })
    return queryParams
  }
  return {}
}

const getSegmentAnonymousId = () => {
  if (typeof window !== 'undefined') {
    // Try to get from cookie first (Segment's default storage)
    const match = document.cookie.match(/ajs_anonymous_id=([^;]+)/)
    if (match) {
      // Remove quotes that Segment adds around the ID
      return match[1].replace(/^"(.*)"$/, '$1')
    }
  }
  return null
}

export const track = (name: string, properties?: TrackingProperties) => {
  const savedParamsString = localStore?.getItem('queryParams') || '{}'
  const savedParams = JSON.parse(savedParamsString)
  const storedUserId = localStore?.getItem(USER_ID_KEY)

  const newProperties = {
    ...savedParams,
    ...properties,
    eventToken: getEventTokenUUID(),
    anonymousId: getSegmentAnonymousId(),
    userId: storedUserId || properties?.userId,
  }
  const context = {
    page: {
      title: name,
      url: `${window.location.origin}/${name}`,
      path: `/${name}`,
      search: window.location.search,
    },
    // Add browser/system information
    userAgent: window.navigator.userAgent,
    language: window.navigator.language,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    locale: window.navigator.languages?.[0] || window.navigator.language,
  }
  if (!shouldTrack()) {
    return
  }
  if (debugSegment()) {
    console.log('Segment Track', name, newProperties)
  } else {
    let eventType = 'track'
    if (properties?.actionType === UserEventType.PAGE_VISIT) {
      eventType = 'page'
    }
    client
      .mutate({
        mutation: LandingTrackUserActionDocument,
        variables: {
          trackUserActionInput: {
            name,
            eventType,
            properties: JSON.stringify(newProperties),
            context: JSON.stringify(context),
          },
        },
      })
      .catch((error: any) => {
        console.error('Error tracking event:', error)
      })
  }
}

export const trackPage = (name: string, properties: any) => {
  let queryParams: { [k: string]: string } = {}
  if (typeof window !== 'undefined') {
    const { search } = window.location
    const params = new URLSearchParams(search)
    params.forEach((value: string, key: string) => {
      queryParams[key] = value
    })
    // tracks the page we just came from
    if (TimeMe.currentPageName !== 'default-page-name') {
      track(name, {
        timeOnPage: TimeMe.getTimeOnCurrentPageInSeconds(),
        pageViewed: TimeMe.currentPageName,
        actionType: UserEventType.PAGE_DURATION,
      })
    }

    TimeMe.stopTimer()
    TimeMe.resetAllRecordedPageTimes()

    TimeMe.setCurrentPageName(window?.location?.pathname)
    TimeMe.startTimer()
  }
  const savedParamsString = localStore?.getItem('queryParams') || '{}'
  const savedParams = JSON.parse(savedParamsString)
  queryParams = {
    ...savedParams,
    ...queryParams,
  }
  localStore?.setItem('queryParams', JSON.stringify(queryParams))
  if (!shouldTrack()) {
    return
  }

  const newProperties = {
    ...properties,
    ...queryParams,
    ...savedParams,
    actionType: UserEventType.PAGE_VISIT,
    timeOnPage: TimeMe.getTimeOnCurrentPageInSeconds(),
    pageViewed: TimeMe.currentPageName,
  }

  track(name, newProperties)
}

export type IdentifyProperties = {
  userId?: string
  email?: string
  name?: string
  phone?: string
  brokerUuid?: string
  utmSource?: string
  utmCampaign?: string
  utmMedium?: string
}

export const identify = (properties: IdentifyProperties) => {
  if (!shouldTrack()) {
    return
  }
  if (properties.userId) {
    localStore?.setItem(USER_ID_KEY, properties.userId)
  }
  if (debugSegment()) {
    console.log('Segment Identify', properties)
  } else {
    client
      .mutate({
        mutation: LandingTrackUserActionDocument,
        variables: {
          trackUserActionInput: {
            name: 'identify',
            eventType: 'identify',
            properties: JSON.stringify({
              ...properties,
              eventToken: getEventTokenUUID(),
            }),
          },
        },
      })
      .catch((error: any) => {
        console.error('Error identifying user:', error)
      })
  }
}

export const getGAClientID = () => {
  // cookie is stored in this format '_ga=GA1.2.358520647.1656458798; _gcl=cookie2'
  if (typeof window !== 'undefined' && window.document.cookie !== '') {
    const match = window.document.cookie.match(/_ga=(.+?)(;|$)/)
    if (match !== null && match.length > 1) {
      return match[1].split('.').slice(-2).join('.')
    }
  }
  return ''
}
